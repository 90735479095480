.s-iam-callback {
  align-items: center;
  background: radial-gradient(ellipse at bottom, #2d4767 0%, #22262f 65%, #22262f 100%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.s-iam-callback__wrapper {
  margin-top: -5rem;
}

.s-iam-callback__logo {
  height: 5rem;
  text-align: center;
}

.s-iam-callback__title {
  color: var(--color-orange);
  font-size: var(--font-size-big);
  margin-bottom: 1em;
  text-align: center;
}

.s-iam-callback__status {
  position: absolute;
  top: 0;
  width: 100%;
}
