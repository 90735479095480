body {
  color: var(--c-text);
  font-family: var(--font-stack);
  font-size: 100%;
  -moz-osx-font-smoothing: inherit;
  -webkit-font-smoothing: antialiased;
  font-weight: var(--fw-regular);
  letter-spacing: -0.01em;
  line-height: var(--line-height-base);
  margin: 0;
  min-height: 100vh;
  padding: 0;
  text-rendering: optimizelegibility;
}

/* stylelint-disable-next-line selector-max-universal */
* {
  box-sizing: border-box;
}
