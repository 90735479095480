.s-authentication {
  align-items: center;
  background: radial-gradient(ellipse at bottom, #2d4767 0%, #22262f 65%, #22262f 100%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.s-authentication__loading-bar {
  left: 0;
  position: fixed;
  top: 0;
  z-index: var(--z-index-top-header);
}

.s-authentication__wrapper {
  margin-top: -5rem;
}

.s-authentication__logo-wrapper {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 3rem;
  text-align: center;
}

.s-authentication__logo-wrapper svg {
  height: 1.5rem;
  width: auto;
}

.s-authentication__title {
  color: var(--color-orange-400);
  line-height: 1;
  margin-bottom: 1em;
  padding-bottom: 0.1rem;
  padding-top: 0;
}

.s-authentication__status {
  position: absolute;
  top: 0;
  width: 100%;
}
