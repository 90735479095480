.s-authentication-form {
  margin: 0 auto;
  width: calc(18rem + 2vw);
}

.s-authentication-form input {
  background: var(--color-space-blue);
  border-bottom-color: rgb(255 255 255 / 30%);
  box-shadow: none;
  color: var(--color-grey-100);
}

.s-authentication-form input:hover,
.s-authentication-form input:focus {
  border-bottom-color: rgb(255 255 255 / 60%);
}

.s-authentication-form label {
  color: rgb(255 255 255 / 50%);
}

.s-authentication-form input:-webkit-autofill,
.s-authentication-form input:-webkit-autofill:hover,
.s-authentication-form input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--color-space-blue) inset;
  -webkit-text-fill-color: var(--color-white);
  transition: background-color 5000s ease-in-out 0s;
}

.s-authentication-form__button {
  margin: 1rem -1% 0;
  width: 102%;
}
